import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
// Webpack Imports
import "core-js/stable";
import "regenerator-runtime/runtime";
import * as bootstrap from 'bootstrap';
import { stringToId } from "../../../plugins/softspring-blocks/src/utils"; // import '../node_modules/bootstrap/js/dist/util';
// import '../node_modules/bootstrap/js/dist/dropdown';

import './scripts/modules/tabs';
import './scripts/modules/value-card';
import './scripts/modules/carousel';
import './scripts/modules/faqs';
import './scripts/modules/lightbox';
import './scripts/modules/form';
import './scripts/modules/two-columns-video-popup';
import { getElement } from "bootstrap/js/src/util";

(function () {
  'use strict'; // Focus i nput if Searchform is empty

  [].forEach.call(document.querySelectorAll('.search-form'), function (el) {
    el.addEventListener('submit', function (e) {
      var search = el.querySelector('input');

      if (search.value.length < 1) {
        e.preventDefault();
        search.focus();
      }
    });
  }); // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, {
      trigger: 'focus'
    });
  });
  var menuTooggle = [].slice.call(document.querySelectorAll('[data-menu-toggle]'));
  menuTooggle.map(function (menuTooggleEl) {
    menuTooggleEl.addEventListener('click', function (event) {
      menuTooggleEl.querySelector('.menu-toggle').classList.toggle('menu-toggle--open');
    });
  });
  var doc = document.documentElement,
      w = window;
  var prevScroll = w.scrollY || doc.scrollTop,
      curScroll,
      direction = 0,
      prevDirection = 0;
  var header = document.querySelector('#header .header-cont');

  var checkScroll = function checkScroll() {
    document.querySelector('.navbar-collapse').classList.remove('show');
    document.querySelector('.menu-toggle').classList.remove('menu-toggle--open');
    /*
          ** Find the direction of scroll
          ** 0 - initial, 1 - up, 2 - down
          */

    curScroll = w.scrollY || doc.scrollTop;

    if (curScroll > prevScroll) {
      //scrolled up
      direction = 2;
    } else if (curScroll < prevScroll) {
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }

    prevScroll = curScroll;
  };

  var toggleHeader = function toggleHeader(direction, curScroll) {
    if (direction === 2 && curScroll > 52) {
      //replace 52 with the height of your header in px
      header.classList.add('hide');
      prevDirection = direction;
    } else if (direction === 1) {
      header.classList.remove('hide');
      prevDirection = direction;
    }
  };

  window.addEventListener('scroll', checkScroll);
})();